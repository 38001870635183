var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "animated fadeIn" },
    [
      _vm.$screen.width <= 567
        ? _c("div")
        : _c(
            "div",
            { staticClass: "container-layout" },
            [
              _c(
                "h1",
                {
                  class: [
                    `${!_vm.$screen.sm ? "main-page-title" : ""}`,
                    { "is-pwa": _vm.$isPwa() },
                  ],
                },
                [
                  _vm._v(
                    "\n\t\t\t" + _vm._s(_vm.FormMSG(1, "Scripts")) + "\n\t\t"
                  ),
                ]
              ),
              _c(
                "b-card",
                { staticClass: "card-border-blue-light" },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12" } },
                        [
                          _vm.$screen.width < 992
                            ? _c("card-list-builder", {
                                attrs: {
                                  items: _vm.scripts,
                                  fields: _vm.fields,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "actions",
                                      fn: function (data) {
                                        return [
                                          _c(
                                            "b-button",
                                            {
                                              staticClass:
                                                "btn-transparent text-color-rhapsody-in-blue",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeItem(
                                                    data.item.id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                _vm.getLucideIcon(
                                                  _vm.ICONS.TRASH.name
                                                ),
                                                {
                                                  tag: "component",
                                                  attrs: {
                                                    color:
                                                      _vm.ICONS.TRASH.color,
                                                    size: 20,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.removeItem(
                                                        data.item.id
                                                      )
                                                    },
                                                  },
                                                }
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1654586323
                                ),
                              })
                            : _vm._e(),
                          _vm.$screen.width >= 992
                            ? _c(
                                "b-row",
                                { staticClass: "form pb-3" },
                                [
                                  _c(
                                    "b-col",
                                    {
                                      attrs: {
                                        sm: "12",
                                        md: "12",
                                        lg: "6",
                                        xl: "6",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-input-group",
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              type: "text",
                                              placeholder: _vm.FormMSG(
                                                2,
                                                "Type to search ..."
                                              ),
                                            },
                                            model: {
                                              value: _vm.filter,
                                              callback: function ($$v) {
                                                _vm.filter = $$v
                                              },
                                              expression: "filter",
                                            },
                                          }),
                                          _c(
                                            "b-input-group-append",
                                            { staticClass: "cursor-pointer" },
                                            [
                                              _c(
                                                "b-input-group-text",
                                                { staticClass: "btn-search" },
                                                [
                                                  _vm.filter.length === 0
                                                    ? _c(
                                                        _vm.getLucideIcon(
                                                          _vm.ICONS.SEARCH.name
                                                        ),
                                                        {
                                                          tag: "component",
                                                          staticClass: "icon",
                                                          attrs: {
                                                            color:
                                                              _vm.ICONS.SEARCH
                                                                .color,
                                                            size: 16,
                                                            "stroke-width": 2.5,
                                                          },
                                                        }
                                                      )
                                                    : _c(
                                                        _vm.getLucideIcon(
                                                          _vm.ICONS.X.name
                                                        ),
                                                        {
                                                          tag: "component",
                                                          staticClass: "icon",
                                                          attrs: {
                                                            color:
                                                              _vm.ICONS.SEARCH
                                                                .color,
                                                            size: 16,
                                                            "stroke-width": 2.5,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.filter = ""
                                                            },
                                                          },
                                                        }
                                                      ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      attrs: {
                                        sm: "12",
                                        md: "12",
                                        lg: "6",
                                        xl: "6",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass:
                                            "btn btn-primary btn-md float-right",
                                          attrs: {
                                            block: !_vm.$screen.md,
                                            variant: "primary",
                                            size: "md",
                                          },
                                          on: { click: _vm.newScript },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.FormMSG(3, "New script")
                                              ) +
                                              "\n\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.$screen.width >= 992
                            ? _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "12", xl: "12" } },
                                    [
                                      _c("b-table", {
                                        ref: "scriptTable",
                                        attrs: {
                                          responsive: "sm",
                                          "selected-variant": _vm.selectedColor,
                                          hover: _vm.hover,
                                          "select-mode": _vm.selectMode,
                                          items: _vm.scripts,
                                          fields: _vm.fields,
                                          "head-variant": _vm.hv,
                                          "empty-text": _vm.FormMSG(
                                            4,
                                            "No script found"
                                          ),
                                          "current-page": _vm.currentPage,
                                          filter: _vm.filter,
                                          "per-page": _vm.perPage,
                                          selectable: "",
                                          bordered: "",
                                          small: "",
                                          "show-empty": "",
                                          "sticky-header": "800px",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "cell(edit)",
                                              fn: function (data) {
                                                return [
                                                  _c(
                                                    _vm.getLucideIcon(
                                                      _vm.ICONS.EDIT.name
                                                    ),
                                                    {
                                                      tag: "component",
                                                      attrs: {
                                                        color:
                                                          _vm.ICONS.EDIT.color,
                                                        size: 18,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.editItem(
                                                            data.item
                                                          )
                                                        },
                                                      },
                                                    }
                                                  ),
                                                ]
                                              },
                                            },
                                            {
                                              key: "cell(download)",
                                              fn: function (data) {
                                                return [
                                                  _c(
                                                    _vm.getLucideIcon(
                                                      _vm.ICONS.DOWNLOAD.name
                                                    ),
                                                    {
                                                      tag: "component",
                                                      attrs: {
                                                        color:
                                                          _vm.ICONS.DOWNLOAD
                                                            .color,
                                                        size: 18,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.downloadItem(
                                                            data.item
                                                          )
                                                        },
                                                      },
                                                    }
                                                  ),
                                                ]
                                              },
                                            },
                                            {
                                              key: "cell(delete)",
                                              fn: function (data) {
                                                return [
                                                  _c(
                                                    _vm.getLucideIcon(
                                                      _vm.ICONS.TRASH.name
                                                    ),
                                                    {
                                                      tag: "component",
                                                      attrs: {
                                                        color:
                                                          _vm.ICONS.TRASH.color,
                                                        size: 20,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.removeItem(
                                                            data.item.id
                                                          )
                                                        },
                                                      },
                                                    }
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          2700596013
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
      _c("form-modal", {
        attrs: {
          title: _vm.titleFormModal,
          action: _vm.action,
          item: _vm.item,
          "odoo-back-log-xid": _vm.odooBackLogXid,
        },
        on: {
          "script-form-modal:closed": _vm.onCancelled,
          "script-form-modal:successed": _vm.onSuccessed,
        },
        model: {
          value: _vm.isScriptFormOpen,
          callback: function ($$v) {
            _vm.isScriptFormOpen = $$v
          },
          expression: "isScriptFormOpen",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }